import { template as template_cb752bb427cb47749a9c887872a8c704 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cb752bb427cb47749a9c887872a8c704(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_ca731cb1ef3343708a5cb7507fed32b9 } from "@ember/template-compiler";
const WelcomeHeader = template_ca731cb1ef3343708a5cb7507fed32b9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
